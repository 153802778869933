import { Link } from 'gatsby'
import { ArrowExternalLink } from 'src/components/Icons/ArrowExternalLink'
import { useMobile } from 'src/hooks/useMobile'

import TimelineCanceled from './TimelineCanceled'
import TimelineProgress from './TimelineProgress'
import type { TimeLineType, Workflow } from './types'

import './orderTimeline.scss'

type OrderTimeLineProps = {
  timeline: TimeLineType
}

const TrackingCode = ({ dispatched }: { dispatched: Workflow | undefined }) => {
  if (dispatched?.confirmed && dispatched?.attachment) {
    return (
      <div className=" text-blue text-sm font-semibold">
        <Link
          target="_blank"
          className="flex gap-1 items-center"
          rel="noreferrer"
          to={dispatched.attachment}
        >
          Rastrear <ArrowExternalLink />
        </Link>
      </div>
    )
  }

  if (dispatched?.confirmed && !dispatched?.attachment) {
    return null
  }

  return (
    <p className="font-inter text-sm font-normal text-[#a4adb7]">
      Seu código de rastreio ficará disponível assim que o pedido for enviado
    </p>
  )
}

const OrderTimeLine = ({ timeline }: OrderTimeLineProps) => {
  const { isMobile } = useMobile()
  const { workflow, currentStatus } = timeline

  const dispatched = workflow.find((item) => item.name === 'tracking-number')

  const isOrderCanceled = currentStatus.name === 'canceled'

  return (
    <div className={`${isMobile ? 'py-[40px]' : 'py-[60px]'}`}>
      <div className="timeline-header mb-8 w-full flex justify-between items-center max-w-[845px]">
        <span className="font-inter text-xl font-medium">
          Status de rastreio
        </span>
        <TrackingCode dispatched={dispatched} />
      </div>
      {isOrderCanceled ? (
        <TimelineCanceled workflow={workflow} />
      ) : (
        <TimelineProgress
          workflow={workflow}
          currentStatus={currentStatus.name}
        />
      )}
    </div>
  )
}

export default OrderTimeLine
