import { useState } from 'react'
import { Modal } from 'src/components/common/Modal'
import LoadingSVG from 'src/components/sections/Account/Loaders/LoadingSVG'
import { getCookie } from 'src/utils/getCookie'
import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import axios from 'axios'

type CancellationModalProps = {
  cancelAllowedOpen: boolean
  setCancelAllowedOpen: React.Dispatch<React.SetStateAction<boolean>>
  orderId: string
}

const CancellationAllowed = ({
  cancelAllowedOpen,
  setCancelAllowedOpen,
  orderId,
}: CancellationModalProps) => {
  const [reasonMessage, setReasonMessage] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [isButtonDisabled, setIsButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)

  const memberAuthToken = getCookie('memberAuthToken')

  const setReasonCancel = (e: any) => {
    setReasonMessage(e.target.value)
    setIsButtonDisabled(false)
  }

  const cancelOrder = async (orderID: string, message: string) => {
    if (!reasonMessage) {
      return setIsButtonDisabled(true)
    }

    setLoading(true)

    try {
      const {
        data: { orderStatus },
      } = await axios.post('/api/account/cancelOrder', {
        orderId: orderID,
        message,
        token: memberAuthToken,
      })

      if (orderStatus === 200) {
        setLoading(false)
        setCancelAllowedOpen(false)

        return window.location.reload()
      }

      setLoading(false)

      return setErrorMessage(
        'Ops! Ocorreu algum problema, por gentileza tente mais tarde.'
      )
    } catch (err) {
      setLoading(false)
      setErrorMessage(
        'Ops! Ocorreu algum problema, por gentileza tente mais tarde.'
      )
      throw new Error(
        'Ops! Ocorreu algum problema, por gentileza tente mais tarde.'
      )
    }
  }

  const reasonsArray = [
    {
      id: 'delay',
      value: 'Pedido está atrasado',
    },
    {
      id: 'information',
      value: 'Não recebi as informações da compra',
    },
    {
      id: 'falsification',
      value: 'Não foi eu quem efetuou a compra',
    },
    {
      id: 'regret',
      value: 'Arrependimento de compra',
    },
  ]

  return (
    <Modal
      isOpen={cancelAllowedOpen}
      onClose={() => {
        setCancelAllowedOpen(false)
      }}
      opacityTailwind="opacity-80"
      className="rounded-lg"
    >
      {!loading ? (
        <div className="flex flex-col justify-center relative w-[347px] pt-6 pb-8 px-7 font-inter md:w-[409px]">
          <Button
            icon={<Icon name="BlackCloseIcon" width={25} height={24} />}
            iconPosition="right"
            onClick={() => {
              setCancelAllowedOpen(false)
            }}
            className="flex items-center justify-center absolute top-2 right-2 w-12 h-12"
          />
          <p className="w-full text-xl font-medium mb-8 md:max-w-[269px]">
            Qual o motivo do seu cancelamento?
          </p>
          <p className="w-full text-[0.875rem] leading-[1.3125rem] font-semibold mb-8">
            Esta operação não poderá ser desfeita
          </p>
          <div className="flex flex-col gap-3 w-full mb-10">
            {reasonsArray.map(({ id, value }) => {
              return (
                <div
                  key={id}
                  className="flex flex-row items-center gap-1.5 w-full text-sm text-[#001018]"
                >
                  <input
                    type="radio"
                    name="cancel"
                    id={id}
                    value={value}
                    onClick={(e) => setReasonCancel(e)}
                    className="w-5 h-5 accent-[#A4ADB7] checked:accent-[#007DBC] hover:!accent-[#007DBC] hover:cursor-pointer"
                  />
                  <label htmlFor={id} className="hover:cursor-pointer">
                    {value}
                  </label>
                </div>
              )
            })}
            {errorMessage && (
              <div className="text-xs text-[#E3262F] mt-2">{errorMessage}</div>
            )}
          </div>
          <div className="flex flex-col gap-3 w-full">
            <Button
              disabled={isButtonDisabled}
              onClick={() => cancelOrder(orderId, reasonMessage)}
              className="flex items-center justify-center w-full h-12 bg-[#15181B] rounded-full text-white text-base font-semibold py-1 px-6 disabled:bg-[#A4ADB7] disabled:text-[#687787]"
            >
              Cancelar pedido
            </Button>
            <Button
              onClick={() => {
                setCancelAllowedOpen(false)
              }}
              className="flex items-center justify-center w-full h-12 bg-white rounded-full text-[#15181B] text-base font-semibold py-1 px-6 border border-solid border-[#C3C9CF]"
            >
              Sair
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col gap-5 items-center justify-center relative w-[347px] h-[461px] px-7 font-inter md:w-[409px]">
          <LoadingSVG />
          <p>Cancelando seu pedido...</p>
        </div>
      )}
    </Modal>
  )
}

export default CancellationAllowed
