import { useState, useEffect } from 'react'
import { useMobile } from 'src/hooks/useMobile'
import { getMonthName } from 'src/utils/orderTimeline'

import type { Workflow } from './types'

type TimelineProgressProps = {
  workflow: Workflow[]
  currentStatus: string
}

const TimelineProgress = ({
  workflow,
  currentStatus,
}: TimelineProgressProps) => {
  const [progress, setProgress] = useState(0)
  const { isMobile } = useMobile()

  function updateProgress() {
    if (currentStatus === 'finished') {
      setProgress(100)

      return
    }

    const stepsDone = workflow.reduce((acc, cur) => {
      if (cur.confirmed) {
        acc += 1
      }

      return acc
    }, 0)

    const currentProgress = (100 / workflow.length) * stepsDone

    setProgress(currentProgress)
  }

  useEffect(() => {
    updateProgress()
  })

  return isMobile ? (
    <div
      id="timeline-shell-mob"
      className="bg-[#F7F8F9] max-w-[24px] rounded-round h-max"
    >
      <ul className="flex flex-col gap-7 h-full justify-between font-inter text-sm font-semibold">
        {workflow.map((item) => {
          return (
            <li
              key={item.name}
              className={`relative bg-red ${item.confirmed && 'progress-done'}`}
              style={{ height: `24px` }}
            >
              <div className="label-wrapper-mob w-max flex items-start gap-2">
                <span>{item.label}</span>
                <small className="font-inter text-sm font-regular text-darkGray">
                  {item.date && getMonthName(item.date)}
                </small>
              </div>
            </li>
          )
        })}
      </ul>
      <div
        style={{ height: `${progress}%` }}
        className="w-[24px]"
        id="timeline-progress"
      />
    </div>
  ) : (
    <div className="mb-[70px]">
      <div
        id="timeline-shell"
        className="flex relative max-w-[845px] h-[24px] bg-[#F7F8F9] rounded-round"
      >
        <div className="flex w-full ">
          <ul
            id="timeline-items-wrapper"
            className="flex w-full font-inter text-sm font-semibold"
          >
            {workflow.map((item) => {
              return (
                <li
                  key={item.name}
                  style={{ width: `${100 / workflow.length}%` }}
                  className={` ${item.confirmed && 'progress-done'}`}
                >
                  <div className="label-wrapper w-full flex flex-col items-start">
                    <span>{item.label}</span>
                    <small className="text-left text-sm font-inter font-regular text-darkGray">
                      {item.date && getMonthName(item.date)}
                    </small>
                  </div>
                </li>
              )
            })}
          </ul>
          <div style={{ width: `${progress}%` }} id="timeline-progress" />
        </div>
      </div>
    </div>
  )
}

export default TimelineProgress
