import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import { Link } from '@faststore/ui'
import { useEffect, useState } from 'react'
import { getDetailsFromTimestamp } from 'src/utils/getDetailsFromTimestamp'
import { formatterPrice } from 'src/utils/formatterPrice'

import BankSlipButton from './BankSlipButton'
import InformativeBlock from '../InformativeBlock'
import RefundModal from '../RefundModal'

type OrderHeaderProps = {
  shippingData: {
    logisticsInfo: Array<{
      shippingEstimate: string
      shippingEstimateDate: string
    }>
    selectedAddresses: any
  }
  creationDate: string
  value: number
  orderId: string
  paymentData: {
    transactions: Array<{
      payments: Array<{
        connectorResponses: any
        group: string
        installments: number
        lastDigits: any
        paymentSystem: string
        paymentSystemName: string
        url: string
        value: number
      }>
    }>
  }
  status: string
  timeline: {
    currentStatus: {
      attachment: any
      confirmed: boolean
      label: string
      name: string
    }
    workflow: Array<{
      attachment: any
      confirmed: boolean
      label: string
      name: string
      date: string
    }>
  }
  sellers: Array<{
    name: string
  }>
  packageAttachment: {
    packages: Array<{
      courier: string
      trackingNumber: string
      invoiceKey: string
      trackingUrl: string
      courierStatus: {
        status: string
        finished: boolean
        deliveredDate: string
        data: Array<{
          lastChange: string
          description: string
        }>
      }
    }>
  }
  setCancelNotAllowedOpen: React.Dispatch<React.SetStateAction<boolean>>
  setCancelAllowedOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const OrderHeader = ({
  shippingData,
  creationDate,
  value,
  orderId,
  paymentData,
  status,
  timeline,
  sellers,
  packageAttachment,
  setCancelNotAllowedOpen,
  setCancelAllowedOpen,
}: OrderHeaderProps) => {
  const [deliveryDeadlineText, setDeliveryDeadlineText] = useState<
    string | undefined
  >()

  const [isRefundModalOpen, setIsRefundModalOpen] = useState(false)

  const shippingEstimate = shippingData?.logisticsInfo?.[0]?.shippingEstimate

  const shippingEstimateDate =
    shippingData?.logisticsInfo?.[0]?.shippingEstimateDate

  const { day: deliveryEstimateDay, month: deliveryEstimateMonth } =
    getDetailsFromTimestamp(shippingEstimateDate)

  const bankSlipUrl = paymentData?.transactions?.[0]?.payments?.[0]?.url

  const sellerName = sellers?.[0]?.name

  const deliveredDate =
    packageAttachment?.packages?.[0]?.courierStatus?.deliveredDate

  const {
    day: deliveredDay,
    month: deliveredMonth,
    hours: deliveredHours,
    minutes: deliveredMinutes,
  } = getDetailsFromTimestamp(deliveredDate)

  const formatFullDate = (date: string) => {
    const dateObj = date

    return new Date(dateObj).toLocaleString('pt-BR', {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    })
  }

  const currentDateTime = new Date().getTime()

  const deliveryDateTime = new Date(shippingEstimateDate).getTime()

  const orderStatus = timeline?.currentStatus?.name

  const isCancellationAllowed =
    orderStatus === 'order-created' ||
    orderStatus === 'payment-approved' ||
    orderStatus === 'handling'

  const isStatusDelivered = orderStatus === 'finished'

  const shippingEstimateNumber = shippingEstimate?.replace(/\D/g, '')

  const deliveryEstimateText = shippingEstimate?.includes('bd')
    ? shippingEstimateNumber === '1'
      ? `Chega em até ${shippingEstimateNumber} dia útil`
      : `Chega em até ${shippingEstimateNumber} dias úteis`
    : `${shippingEstimate}`

  const DeliveredText = `Chegou dia ${deliveredDay}.${deliveredMonth}`

  const deliveryEstimateDateText = `Chega até dia ${deliveryEstimateDay}.${deliveryEstimateMonth}`

  const isAwaitingPayment = status === 'payment-pending' && !!bankSlipUrl

  const cancellationDate = timeline?.workflow?.filter(
    (e) => e.name === 'canceled'
  )[0]?.date

  const { day: cancellationDay, month: cancellationMonth } =
    getDetailsFromTimestamp(cancellationDate)

  const cancellationDateText = `Cancelado dia ${cancellationDay}.${cancellationMonth}`

  useEffect(() => {
    switch (orderStatus) {
      case 'order-created':
        setDeliveryDeadlineText(deliveryEstimateText)

        return

      case 'finished':
        setDeliveryDeadlineText(DeliveredText)

        return

      case 'canceled':
        setDeliveryDeadlineText(cancellationDateText)

        return

      default:
        setDeliveryDeadlineText(deliveryEstimateDateText)
    }
  }, [
    DeliveredText,
    cancellationDateText,
    deliveryEstimateDateText,
    deliveryEstimateText,
    orderStatus,
  ])

  const orderDeliveredBlockProps = {
    type: 'success',
    icon: true,
    title: 'Seu pedido foi entregue',
    body: {
      main: `Pedido entregue dia ${deliveredDay}.${deliveredMonth} ás ${deliveredHours}:${deliveredMinutes}`,
      secondary: `${
        sellerName === 'Iguasport LTDA' || sellerName === 'decathlonpro'
          ? 'Você tem até 45 dias para realizar a troca, ou até 07 dias para a devolução.'
          : 'Você tem até 7 dias para a devolução.'
      }`,
    },
  }

  const orderCancelledBlockProps = {
    type: 'warn',
    icon: true,
    title: 'Seu pedido foi cancelado',
    body: {
      main: 'Caso o pagamento já tenha sido confirmado iremos processar o reembolso do seu dinheiro.',
    },
    cta: {
      style: 'link',
      text: 'Como receberei meu reembolso',
      clickAction: () => setIsRefundModalOpen(true),
    },
  }

  const orderDelayedBlockProps = {
    type: 'error',
    body: {
      main: 'Tivemos um imprevisto, sua entrega pode ter um pequeno atraso. Já estamos tomando as devidas providências.',
    },
  }

  const orderAwaitingPaymentBlockProps = {
    type: 'warn',
    icon: true,
    title: 'Atenção! Finalize seu pagamento',
    body: {
      main: 'Realize o pagamento para dar continuidade ao seu pedido, caso já tenha pago aguarde o processamento do pagamento (até 48h)',
    },
    cta: {
      style: 'button',
      text: 'Baixar boleto',
      link: bankSlipUrl,
    },
  }

  return (
    <div className="flex flex-col pb-8 md:border-b md:border-solid md:border-[#E1E4E7]">
      {isRefundModalOpen && (
        <RefundModal
          isRefundModalOpen={isRefundModalOpen}
          setIsRefundModalOpen={setIsRefundModalOpen}
        />
      )}
      <p className="text-2xl font-medium text-[#15181B] mb-2">
        {deliveryDeadlineText}
      </p>
      <div className="flex flex-col w-full md:flex-col-reverse">
        {isStatusDelivered && (
          <InformativeBlock {...orderDeliveredBlockProps} />
        )}
        {orderStatus === 'canceled' && (
          <InformativeBlock {...orderCancelledBlockProps} />
        )}
        {currentDateTime > deliveryDateTime &&
          !isStatusDelivered &&
          !!shippingEstimateDate && (
            <InformativeBlock {...orderDelayedBlockProps} />
          )}
        {isAwaitingPayment && (
          <InformativeBlock {...orderAwaitingPaymentBlockProps} />
        )}
        <div className="flex flex-col-reverse justify-between w-full text-[0.75rem] leading-[1.125rem] text-[#687787] md:flex-row">
          <div className="flex flex-col mt-8 md:flex-row md:gap-4 md:mt-0 md:items-center">
            <p>{`Data: ${formatFullDate(creationDate)}`}</p>
            <p>{formatterPrice(value / 100)}</p>
            <div className="flex items-center gap-2">
              <p>{`Pedido #${orderId}`}</p>
              <Button
                type="button"
                icon={<Icon name="CopyOrder" width={16} height={16} />}
                iconPosition="right"
                onClick={() => {
                  navigator.clipboard.writeText(orderId)
                }}
              />
            </div>
          </div>
          <div className="flex gap-6">
            {isAwaitingPayment && <BankSlipButton bankSlipUrl={bankSlipUrl} />}
            {isStatusDelivered ? (
              <Link
                href={
                  sellerName === 'Iguasport LTDA' ||
                  sellerName === 'decathlonpro'
                    ? 'https://decathlon.troquefacil.com.br/'
                    : 'https://decathlonprodutosparceiros.troquefacil.com.br/'
                }
                className="flex w-full h-[59px] items-center justify-between !px-4 border border-solid border-[#E1E4E7] text-base font-semibold text-[#15181B] md:h-full md:text-[0.875rem] md:leading-[1.3125rem] md:text-[#007DBC] md:border-none md:!p-0 hover:!no-underline"
                target="_blank"
              >
                <div className="flex items-center gap-4">
                  <Icon
                    className="md:hidden"
                    name="ExchangeIcon"
                    width={24}
                    height={24}
                  />
                  Trocar ou devolver
                </div>
                <Icon
                  className="md:hidden"
                  name="BlackRightArrow"
                  width={16}
                  height={16}
                />
              </Link>
            ) : (
              <>
                {orderStatus !== 'canceled' && (
                  <Button
                    type="button"
                    className="text-[0.875rem] leading-[1.3125rem] font-semibold !text-[#007DBC]"
                    onClick={() => {
                      isCancellationAllowed
                        ? setCancelAllowedOpen(true)
                        : setCancelNotAllowedOpen(true)
                    }}
                  >
                    Cancelar compra
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OrderHeader
