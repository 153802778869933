import { Link } from 'gatsby'
import Icon from 'src/components/ui/Icon'

type BankSlipButtonProps = {
  bankSlipUrl: string
}

const BankSlipButton = ({ bankSlipUrl }: BankSlipButtonProps) => {
  return (
    <Link
      to={bankSlipUrl}
      target="_blank"
      className="flex items-center gap-[5px] text-[0.875rem] leading-[1.3125rem] font-semibold text-[#007DBC]"
      rel="noreferrer"
    >
      Pagar boleto <Icon name="ArrowUpRight" width={16} height={16} />
    </Link>
  )
}

export default BankSlipButton
