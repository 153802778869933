import axios from 'axios'
import { useEffect, useState } from 'react'
import { getCookie } from 'src/utils/getCookie'
import OrderBreadcrumb from 'src/components/account/OrderDetail/OrderBreadcrumb'
import OrderHeader from 'src/components/account/OrderDetail/OrderHeader'
import OrderDetails from 'src/components/account/OrderDetail/OrderDetails'
import CancellationNotAllowed from 'src/components/account/OrderDetail/CancellationModal/CancellationNotAllowed'
import CancellationAllowed from 'src/components/account/OrderDetail/CancellationModal/CancellationAllowed'
import OrderTimeLine from 'src/components/account/Orders/components/Timeline/OrderTimeLine'

import OrderContentSkeleton from './OrderContentSkeleton'

type OrderContentProps = {
  orderId: string
}

const OrderContent = ({ orderId }: OrderContentProps) => {
  const [cancelNotAllowedOpen, setCancelNotAllowedOpen] = useState(false)
  const [cancelAllowedOpen, setCancelAllowedOpen] = useState(false)
  const [page, setPage] = useState<number>(0)
  const [orders, setOrders] = useState<any>()
  const [order, setOrder] = useState<any>()

  const memberAuthToken = getCookie('memberAuthToken')

  useEffect(() => {
    if (!memberAuthToken || !page) {
      return
    }

    const getOrderList = async () => {
      const { data } = await axios.post('/api/getOrderList', {
        email: memberAuthToken,
        page,
      })

      setOrders(data.response.data.DktApp_GetOrderList.list)
    }

    getOrderList()
  }, [memberAuthToken, page])

  useEffect(() => {
    if (!orders) {
      return
    }

    const filteredOrder = orders.find(
      (orderItem: any) => orderItem.orderId === orderId
    )

    setOrder(filteredOrder)
  }, [orders, orderId])

  useEffect(() => {
    const urlSearchString = window.location.search

    const params = new URLSearchParams(urlSearchString)
    const hasPage = params.get('page')

    setPage(hasPage ? parseInt(hasPage, 10) : 1)
  }, [])

  if (!order) {
    return (
      <div className="w-full max-w-[844px]">
        <OrderContentSkeleton orderId={orderId} />
      </div>
    )
  }

  const orderGroup = order?.orderId?.split('-')[0]

  const isOrderSplit =
    orders?.filter((orderItem: any) => orderItem?.orderId?.includes(orderGroup))
      .length > 1

  return (
    <section className="w-full md:max-w-[845px]">
      {cancelNotAllowedOpen && (
        <CancellationNotAllowed
          cancelNotAllowedOpen={cancelNotAllowedOpen}
          setCancelNotAllowedOpen={setCancelNotAllowedOpen}
        />
      )}
      {cancelAllowedOpen && (
        <CancellationAllowed
          cancelAllowedOpen={cancelAllowedOpen}
          setCancelAllowedOpen={setCancelAllowedOpen}
          orderId={orderId}
        />
      )}
      <OrderBreadcrumb orderId={orderId} />

      <OrderHeader
        {...order}
        setCancelNotAllowedOpen={setCancelNotAllowedOpen}
        setCancelAllowedOpen={setCancelAllowedOpen}
      />

      {order.timeline.currentStatus.name !== 'finished' && (
        <OrderTimeLine timeline={order.timeline} />
      )}

      <OrderDetails {...order} isOrderSplit={isOrderSplit} />

      {order.timeline.currentStatus.name === 'finished' && (
        <OrderTimeLine timeline={order.timeline} />
      )}
    </section>
  )
}

export default OrderContent
