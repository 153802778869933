import { Link } from '@faststore/ui'
import { Modal } from 'src/components/common/Modal'
import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'

type CancellationModalProps = {
  cancelNotAllowedOpen: boolean
  setCancelNotAllowedOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const CancellationNotAllowed = ({
  cancelNotAllowedOpen,
  setCancelNotAllowedOpen,
}: CancellationModalProps) => {
  return (
    <Modal
      isOpen={cancelNotAllowedOpen}
      onClose={() => {
        setCancelNotAllowedOpen(false)
      }}
      opacityTailwind="opacity-80"
      className="rounded-lg"
    >
      <div className="flex flex-col items-center justify-center relative w-[347px] pt-12 pb-8 px-4 font-inter md:w-[409px]">
        <Button
          icon={<Icon name="BlackCloseIcon" width={25} height={24} />}
          iconPosition="right"
          onClick={() => {
            setCancelNotAllowedOpen(false)
          }}
          className="flex items-center justify-center absolute top-2 right-2 w-12 h-12"
        />
        <p className="w-[251px] text-center text-[0.875rem] leading-[1.3125rem] mb-8 md:w-[313px]">
          O pedido já foi faturado, por conta disso, para realizar o
          cancelamento entre em contato com nosso time de atendimento
        </p>
        <div className="flex flex-col gap-3 w-full">
          <Link
            href="https://www.decathlon.com.br/atendimento"
            target="_blank"
            className="flex items-center justify-center w-full h-12 bg-[#15181B] rounded-full text-white font-semibold py-1 px-6 hover:!no-underline"
          >
            Falar com o atendimento
          </Link>
          <Button
            onClick={() => {
              setCancelNotAllowedOpen(false)
            }}
            className="flex items-center justify-center w-full h-12 bg-white rounded-full text-[#15181B] font-semibold py-1 px-6 border border-solid border-[#C3C9CF] hover:!no-underline"
          >
            Sair
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default CancellationNotAllowed
