import type { OrderItems } from 'src/types/account/order'
import { stripUrl } from 'src/utils/stripUrl'
import { Image } from 'src/components/ui/Image'
import { useMobile } from 'src/hooks/useMobile'
import { Link } from 'gatsby'
import { formatterPrice } from 'src/utils/formatterPrice'

interface Props {
  products: [OrderItems]
  orderShipped: boolean
}

export const ProductList = ({ products, orderShipped }: Props) => {
  const { isMobile } = useMobile()

  return (
    <div className="divide-y divide-lighestGray sm:divide-none">
      {products.map(
        (
          {
            imageUrl,
            name,
            quantity,
            price,
            manufacturerCode,
            measurementUnit,
            detailUrl,
          },
          i
        ) => {
          return (
            <div
              className="flex flex-col justify-between gap-6 sm:flex-row sm:gap-28"
              key={i}
            >
              <div className="flex flex-1 gap-4 py-6 sm:py-3 first-of-type:pt-0 last-of-type:pb-0">
                <div className="bg-[#f7f8f9] opacity-100 p-2 rounded-md">
                  <Image
                    src={stripUrl(imageUrl)}
                    alt={name}
                    width={isMobile ? 90 : 76}
                    height={isMobile ? 90 : 76}
                    className="mix-blend-multiply"
                  />
                </div>
                <div className="flex flex-col w-full gap-3">
                  <div className="flex flex-col flex-none gap-3 text-xs xs:mb-0">
                    <Link
                      className="text-[#15181B] no-underline"
                      to={detailUrl}
                    >
                      {name}
                    </Link>
                  </div>
                  <span className="font-semibold">
                    {formatterPrice((price * quantity) / 100)}
                    <small className="text-xs ml-1">
                      {quantity}
                      {measurementUnit}
                    </small>
                  </span>
                </div>
              </div>
              {manufacturerCode !== null && orderShipped && (
                <button className="bg-[#15181B] text-white text-sm font-semibold rounded-full px-6 h-8 w-[152px]">
                  Avaliar produto
                </button>
              )}
            </div>
          )
        }
      )}
    </div>
  )
}
